/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://12rb057ki8.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "addressApi",
            "endpoint": "https://euiei0hlgc.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "requestApi",
            "endpoint": "https://6ckvh84ns4.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "transactionApi",
            "endpoint": "https://58pxog4gz2.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://abnqswqnvzcnlkorws5xbtifhi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tjjntor7sna6vn3pddulm76woi",
    "aws_cognito_identity_pool_id": "us-east-2:41f552e9-ad42-4aa6-a83f-839f6e58c0b2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_FPYZohkwF",
    "aws_user_pools_web_client_id": "4d4b01augv8ouc519d7nm6rjj0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bitcoin-storage-fbac3250130230-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
