import { config } from "@fortawesome/fontawesome-svg-core"
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons"
import {
  faArrowRight,
  faCheck,
  faCircle,
  faCircleDollarToSlot,
  faFlag,
  faGear,
  faLock,
  faMoneyBillTransfer,
  faPowerOff,
  faShield,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AOS from "aos"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"
import { FORM_STATUS, TYPE_COMPANY } from "../../utils/constants"
import {
  onGetTier,
  onOpenPage,
  onScrollTop,
  onSignOut,
} from "../../utils/utils"
// font awesome: disable the auto css insertion
config.autoAddCss = false

export default function SideNav(props) {
  const {
    page,
    activeTab,
    setActiveTab,
    userLevel,
    recordOne,
    recordTwo,
    recordThree,
    recordFour,
    recordFive,
    recent,
  } = props

  const [loading, setLoading] = useState(true)
  const [tier, setTier] = useState(0)

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-quart",
    })
    AOS.refresh()
  }, [])

  useEffect(() => {
    if (userLevel && Object.keys(userLevel).length > 0) {
      setTier(onGetTier(userLevel?.level))
    } else {
      setTier(onGetTier("0"))
    }
  }, [userLevel])

  useEffect(() => {
    const getActiveTab = async () => {
      try {
        setLoading(true)
        // set active tab
        if (
          Object.keys(recordFive).length > 0 &&
          recordFive?.approve !== /* rejected */ FORM_STATUS[1].label &&
          recordFive?.approve !== /* pending */ FORM_STATUS[0].label
        ) {
          setActiveTab(recent === "5" ? "tab-tier-4" : "tab-tier-1")
        } else if (
          Object.keys(recordFour).length > 0 &&
          recordFour?.approve !== /* rejected */ FORM_STATUS[1].label
        ) {
          setActiveTab(recent === "4" ? "tab-tier-3" : "tab-tier-4")
        } else if (
          Object.keys(recordThree).length > 0 &&
          recordThree?.approve !== /* rejected */ FORM_STATUS[1].label
        ) {
          setActiveTab(recent === "3" ? "tab-tier-2.2" : "tab-tier-3")
        } else if (
          Object.keys(recordTwo).length > 0 &&
          recordTwo?.approve !== /* rejected */ FORM_STATUS[1].label
        ) {
          setActiveTab(recent === "2" ? "tab-tier-2.1" : "tab-tier-2.2")
        } else if (
          Object.keys(recordOne).length > 0 &&
          recordOne?.approve !== /* rejected */ FORM_STATUS[1].label
        ) {
          setActiveTab(recent === "1" ? "tab-tier-1" : "tab-tier-2.1")
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    getActiveTab()
  }, [
    recordOne,
    recordTwo,
    recordThree,
    recordFour,
    recordFive,
    setActiveTab,
    recent,
  ])

  const handleTabDashboard = () => {
    page !== "dashboard" ? onOpenPage("dashboard") : onScrollTop()
  }

  const handleTabRequests = () => {
    page !== "requests" ? onOpenPage("requests") : onScrollTop()
  }

  const handleTabTransactions = () => {
    page !== "transactions" ? onOpenPage("transactions") : onScrollTop()
  }

  const handleTabAppointments = () => {
    page !== "appointments" ? onOpenPage("appointments") : onScrollTop()
  }

  const handleTabSettings = () => {
    page !== "settings" ? onOpenPage("settings") : onScrollTop()
  }

  const handleTab = e => {
    if (Object.keys(userLevel).length > 0) {
      switch (e.currentTarget.id) {
        case "tab1":
          if (parseInt(userLevel?.level) >= 0) {
            setActiveTab("tab-tier-1")
            onScrollTop()
          }
          break

        case "tab2.1":
          if (
            (parseInt(userLevel?.level) >= 1 && userLevel?.status === true) ||
            Object.keys(recordOne).length > 0
          ) {
            setActiveTab("tab-tier-2.1")
            onScrollTop()
          }
          break

        case "tab2.2":
          if (
            parseInt(userLevel?.level) >= 2 ||
            (parseInt(userLevel?.level) >= 1 && userLevel?.status === true) ||
            Object.keys(recordTwo).length > 0
          ) {
            setActiveTab("tab-tier-2.2")
            onScrollTop()
          }
          break

        case "tab3":
          if (
            parseInt(userLevel?.level) >= 3 ||
            (parseInt(userLevel?.level) >= 2 && userLevel?.status === true) ||
            Object.keys(recordThree).length > 0
          ) {
            setActiveTab("tab-tier-3")
            onScrollTop()
          }
          break

        case "tab4":
          if (
            parseInt(userLevel?.level) >= 4 ||
            (parseInt(userLevel?.level) >= 3 && userLevel?.status === true) ||
            Object.keys(recordFour).length > 0
          ) {
            setActiveTab("tab-tier-4")
            onScrollTop()
          }
          break

        case "tab-security":
          if (parseInt(userLevel?.level) >= 0) {
            setActiveTab("tab-security")
            onScrollTop()
          }
          break

        default:
          break
      }
    } else {
      setActiveTab("tab-tier-1")
      onScrollTop()
    }
  }

  return (
    <div
      id="nav"
      className="lg:w-56 md:w-52 w-fit h-fit relative z-40 flex flex-col"
    >
      {!loading ? (
        <div>
          <div
            className={`w-full h-fit lg:mt-8 md:mt-6 mt-4 lg:pt-8 md:pt-6 pt-4 lg:pb-4 md:pb-3 pb-2 lg:px-4 md:px-3 px-2 flex-col justify-center items-center text-center bg-primary-light rounded-3xl drop-shadow ${
              parseInt(tier) >= 1 ? "flex" : "hidden"
            } `}
          >
            <div className="flex justify-center font-bold text-secondary-default">
              TIER
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="200"
              className="lg:mt-4 md:mt-3 mt-2 flex justify-center lg:text-7xl md:text-6xl text-5xl font-semibold text-primary-default"
            >
              {tier}
            </div>
          </div>

          <div className="w-full h-fit lg:mt-8 md:mt-6 mt-4 lg:py-8 md:py-6 py-4 lg:px-4 md:px-3 px-2 flex flex-col justify-start items-center bg-primary-light rounded-3xl drop-shadow">
            <ul className="w-full h-fit m-0 flex flex-col lg:justify-normal md:justify-normal justify-center lg:items-start md:items-start items-center">
              <li className="w-fit h-fit mb-8">
                <button
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold cursor-pointer outline-none ${
                    page === "dashboard"
                      ? "text-primary-dark"
                      : "text-primary-default hover:text-primary-dark"
                  }`}
                  onClick={handleTabDashboard}
                >
                  <div className="flex justify-center">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="w-6 h-6 self-center"
                    />
                  </div>

                  <span className="lg:w-32 md:w-32 w-fit lg:ml-4 md:ml-3 ml-0 lg:flex md:flex hidden whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    Dashboard
                  </span>
                </button>
              </li>

              <li className="w-fit h-fit mb-8">
                <button
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold cursor-pointer outline-none ${
                    page === "requests" || page === "request"
                      ? "text-primary-dark"
                      : "text-primary-default hover:text-primary-dark"
                  }`}
                  onClick={handleTabRequests}
                >
                  <div className="flex justify-center">
                    <FontAwesomeIcon
                      icon={faCalendarCheck}
                      className="w-6 h-6 self-center"
                    />
                  </div>

                  <span className="lg:w-32 md:w-32 w-fit lg:ml-4 md:ml-3 ml-0 lg:flex md:flex hidden whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    Appointments
                  </span>
                </button>
              </li>

              <li className="w-fit h-fit mb-8">
                <button
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold cursor-pointer outline-none ${
                    page === "requests" || page === "request"
                      ? "text-primary-dark"
                      : "text-primary-default hover:text-primary-dark"
                  }`}
                  onClick={handleTabRequests}
                >
                  <div className="w-6 h-6 flex justify-center">
                    <FontAwesomeIcon
                      icon={faCircleDollarToSlot}
                      className="w-6 h-6 self-center"
                    />
                  </div>

                  <span className="lg:w-32 md:w-32 w-fit lg:ml-4 md:ml-3 ml-0 lg:flex md:flex hidden whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    Requests
                  </span>
                </button>
              </li>

              <li className="w-fit h-fit mb-8">
                <button
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold cursor-pointer outline-none ${
                    page === "transactions" || page === "transaction"
                      ? "text-primary-dark"
                      : "text-primary-default hover:text-primary-dark"
                  }`}
                  onClick={handleTabTransactions}
                >
                  <div className="w-6 h-6 flex justify-center">
                    <FontAwesomeIcon
                      icon={faMoneyBillTransfer}
                      className="w-6 h-6 self-center"
                    />
                  </div>

                  <span className="lg:w-32 md:w-32 w-fit lg:ml-4 md:ml-3 ml-0 lg:flex md:flex hidden whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    Transactions
                  </span>
                </button>
              </li>

              <li className="w-fit h-fit mb-8">
                <button
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold cursor-pointer outline-none ${
                    page === "settings"
                      ? "text-primary-dark"
                      : "text-primary-default hover:text-primary-dark"
                  }`}
                  onClick={handleTabSettings}
                >
                  <div className="flex justify-center">
                    <FontAwesomeIcon
                      icon={faGear}
                      className="w-6 h-6 self-center"
                    />
                  </div>

                  <span className="lg:w-32 md:w-32 w-fit lg:ml-4 md:ml-3 ml-0 lg:flex md:flex hidden whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    Settings
                  </span>
                </button>
              </li>

              <li
                className={`w-fit h-fit mb-8 ${page !== "settings" && "hidden"}`}
              >
                <button
                  id="tab1"
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold cursor-pointer outline-none ${
                    activeTab === "tab-tier-1"
                      ? "active text-primary-dark"
                      : "text-primary-default hover:text-primary-dark"
                  }`}
                  data-tooltip-id="tooltip-tier-1"
                  data-tooltip-content={`${
                    userLevel !== undefined &&
                    userLevel !== null &&
                    Object.keys(userLevel).length > 0 &&
                    userLevel?.account === TYPE_COMPANY
                      ? "Company Form"
                      : "Individual Form"
                  }`}
                  data-tooltip-place="right"
                  onClick={handleTab}
                >
                  <div className="w-6 h-6 lg:flex md:flex hidden justify-center">
                    {userLevel !== undefined &&
                    Object.keys(userLevel).length > 0 &&
                    parseInt(userLevel?.level) >= 1 &&
                    Object.keys(recordOne).length > 0 &&
                    recordOne?.approve ===
                      /* approved */ FORM_STATUS[2].label ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="w-5 h-5 self-center"
                      />
                    ) : activeTab === "tab-tier-1" ? (
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="w-4 h-4 self-center"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="w-2 h-2 self-center"
                      />
                    )}
                  </div>

                  <span className="lg:w-32 md:w-32 w-fit lg:ml-4 md:ml-3 ml-0 flex whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    T<span className="lg:flex md:flex hidden">ier</span>
                    &nbsp;1
                  </span>
                </button>
                <Tooltip
                  id="tooltip-tier-1"
                  className="bg-primary-default text-white"
                />
              </li>

              <li
                className={`w-fit h-fit mb-8 ${page !== "settings" && "hidden"}`}
              >
                <button
                  id="tab2.1"
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold outline-none ${
                    Object.keys(userLevel).length > 0 &&
                    (parseInt(userLevel?.level) >= 1 ||
                      (parseInt(userLevel?.level) === 0 && userLevel?.status) ||
                      (Object.keys(recordOne).length > 0 &&
                        recordOne?.approve !== "0"))
                      ? activeTab === "tab-tier-2.1"
                        ? "active text-primary-dark cursor-pointer"
                        : "text-primary-default hover:text-primary-dark cursor-pointer"
                      : "inactive text-inactive cursor-not-allowed"
                  }`}
                  data-tooltip-id="tooltip-tier-2.1"
                  data-tooltip-content="Contact Form"
                  data-tooltip-place="right"
                  onClick={handleTab}
                >
                  <div className="w-6 h-6 lg:flex md:flex hidden justify-center">
                    {Object.keys(userLevel).length > 0 &&
                    parseInt(userLevel?.level) >= 2 &&
                    Object.keys(recordTwo).length > 0 &&
                    recordTwo?.approve ===
                      /* approved */ FORM_STATUS[2].label ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="w-5 h-5 self-center"
                      />
                    ) : (Object.keys(userLevel).length > 0 &&
                        parseInt(userLevel?.level) === 0 &&
                        userLevel?.status === true) ||
                      (Object.keys(recordOne).length > 0 &&
                        recordOne?.approve !== "0") ? (
                      activeTab === "tab-tier-2.1" ? (
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="w-4 h-4 self-center"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="w-2 h-2 self-center"
                        />
                      )
                    ) : (
                      <FontAwesomeIcon
                        icon={faLock}
                        className="w-4 h-4 lg:flex md:flex hidden self-center text-inactive"
                      />
                    )}
                  </div>

                  <span className="lg:w-settings-tier-lg md:w-settings-tier-md w-fit lg:ml-4 md:ml-3 ml-0 flex whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    T<span className="lg:flex md:flex hidden">ier</span>
                    &nbsp;2.1
                  </span>

                  <div className="w-7 h-7 lg:flex md:flex hidden justify-center">
                    {recent === "1" && Object.keys(recordOne).length > 0 && (
                      <FontAwesomeIcon
                        icon={faFlag}
                        className="w-5 h-5 self-center text-button-success animate-bounce"
                      />
                    )}
                  </div>
                </button>
                <Tooltip
                  id="tooltip-tier-2.1"
                  className={`${
                    (userLevel !== undefined &&
                      userLevel !== null &&
                      userLevel?.level !== undefined &&
                      userLevel?.level !== null &&
                      Object.keys(userLevel?.level).length >= 2) ||
                    Object.keys(recordOne).length > 0
                      ? "bg-primary-default text-white"
                      : "hidden"
                  }`}
                />
              </li>

              <li
                className={`w-fit h-fit mb-8 ${page !== "settings" && "hidden"}`}
              >
                <button
                  id="tab2.2"
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold outline-none ${
                    Object.keys(userLevel).length > 0 &&
                    (parseInt(userLevel?.level) >= 2 ||
                      (parseInt(userLevel?.level) === 1 && userLevel?.status) ||
                      (Object.keys(recordTwo).length > 0 &&
                        recordTwo?.approve !== "0"))
                      ? activeTab === "tab-tier-2.2"
                        ? "active text-primary-dark cursor-pointer"
                        : "text-primary-default hover:text-primary-dark cursor-pointer"
                      : "inactive text-inactive cursor-not-allowed"
                  }`}
                  data-tooltip-id="tooltip-tier-2.2"
                  data-tooltip-content={`${
                    userLevel !== undefined &&
                    userLevel !== null &&
                    userLevel?.level !== undefined &&
                    userLevel?.level !== null &&
                    Object.keys(userLevel?.level).length >= 3 &&
                    userLevel?.account === TYPE_COMPANY
                      ? "Business Form"
                      : "Employment Form"
                  }`}
                  data-tooltip-place="right"
                  onClick={handleTab}
                >
                  <div className="w-6 h-6 lg:flex md:flex hidden justify-center">
                    {Object.keys(userLevel).length > 0 &&
                    parseInt(userLevel?.level) >= 3 &&
                    Object.keys(recordThree).length > 0 &&
                    recordThree?.approve ===
                      /* approved */ FORM_STATUS[2].label ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="w-5 h-5 self-center"
                      />
                    ) : (Object.keys(userLevel).length > 0 &&
                        parseInt(userLevel?.level) === 1 &&
                        userLevel?.status === "true") ||
                      (Object.keys(recordTwo).length > 0 &&
                        recordTwo?.approve !== "0") ? (
                      activeTab === "tab-tier-2.2" ? (
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="w-4 h-4 self-center"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="w-2 h-2 self-center"
                        />
                      )
                    ) : (
                      <FontAwesomeIcon
                        icon={faLock}
                        className="w-4 h-4 lg:flex md:flex hidden self-center text-inactive"
                      />
                    )}
                  </div>

                  <span className="lg:w-settings-tier-lg md:w-settings-tier-md w-fit lg:ml-4 md:ml-3 ml-0 flex whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    T<span className="lg:flex md:flex hidden">ier</span>
                    &nbsp;2.2
                  </span>

                  <div className="w-7 h-7 lg:flex md:flex hidden justify-center">
                    {recent === "2" && Object.keys(recordTwo).length > 0 && (
                      <FontAwesomeIcon
                        icon={faFlag}
                        className="w-5 h-5 self-center text-button-success animate-bounce"
                      />
                    )}
                  </div>
                </button>
                <Tooltip
                  id="tooltip-tier-2.2"
                  className={`${
                    (userLevel !== undefined &&
                      userLevel !== null &&
                      userLevel?.level !== undefined &&
                      userLevel?.level !== null &&
                      Object.keys(userLevel?.level).length >= 3) ||
                    Object.keys(recordTwo).length > 0
                      ? "bg-primary-default text-white"
                      : "hidden"
                  }`}
                />
              </li>

              <li
                className={`w-fit h-fit mb-8 ${page !== "settings" && "hidden"}`}
              >
                <button
                  id="tab3"
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold outline-none ${
                    Object.keys(userLevel).length > 0 &&
                    (parseInt(userLevel?.level) >= 3 ||
                      (parseInt(userLevel?.level) === 2 && userLevel?.status) ||
                      (Object.keys(recordThree).length > 0 &&
                        recordThree?.approve !== "0"))
                      ? activeTab === "tab-tier-3"
                        ? "active text-primary-dark cursor-pointer"
                        : "text-primary-default hover:text-primary-dark cursor-pointer"
                      : "inactive text-inactive cursor-not-allowed"
                  }`}
                  data-tooltip-id="tooltip-tier-3"
                  data-tooltip-content="Source of Funds Form"
                  data-tooltip-place="right"
                  onClick={handleTab}
                >
                  <div className="w-6 h-6 lg:flex md:flex hidden justify-center">
                    {Object.keys(userLevel).length > 0 &&
                    parseInt(userLevel?.level) >= 4 &&
                    Object.keys(recordFour).length > 0 &&
                    recordFour?.approve ===
                      /* approved */ FORM_STATUS[2].label ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="w-5 h-5 self-center"
                      />
                    ) : (Object.keys(userLevel).length > 0 &&
                        parseInt(userLevel?.level) === 2 &&
                        userLevel?.status === true) ||
                      (Object.keys(recordThree).length > 0 &&
                        recordThree?.approve !== "0") ? (
                      activeTab === "tab-tier-3" ? (
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="w-4 h-4 self-center"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="w-2 h-2 self-center"
                        />
                      )
                    ) : (
                      <FontAwesomeIcon
                        icon={faLock}
                        className="w-4 h-4 lg:flex md:flex hidden self-center text-inactive"
                      />
                    )}
                  </div>

                  <span className="lg:w-settings-tier-lg md:w-settings-tier-md w-fit lg:ml-4 md:ml-3 ml-0 flex whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    T<span className="lg:flex md:flex hidden">ier</span>
                    &nbsp;3
                  </span>

                  <div className="w-5 h-5 lg:flex md:flex hidden justify-center">
                    {recent === "3" &&
                      Object.keys(recordTwo).length > 0 &&
                      Object.keys(recordThree).length > 0 && (
                        <FontAwesomeIcon
                          icon={faFlag}
                          className="w-5 h-5 self-center text-button-success animate-bounce"
                        />
                      )}
                  </div>
                </button>
                <Tooltip
                  id="tooltip-tier-3"
                  className={`${
                    (userLevel !== undefined &&
                      userLevel !== null &&
                      userLevel?.level !== undefined &&
                      userLevel?.level !== null &&
                      Object.keys(userLevel?.level).length >= 4) ||
                    Object.keys(recordThree).length > 0
                      ? "bg-primary-default text-white"
                      : "hidden"
                  }`}
                />
              </li>

              <li
                className={`w-fit h-fit mb-8 ${page !== "settings" && "hidden"}`}
              >
                <button
                  id="tab4"
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold outline-none ${
                    Object.keys(userLevel).length > 0 &&
                    (parseInt(userLevel?.level) >= 4 ||
                      (parseInt(userLevel?.level) === 3 && userLevel?.status) ||
                      (Object.keys(recordFour).length > 0 &&
                        recordFour?.approve !== "0"))
                      ? activeTab === "tab-tier-4"
                        ? "active text-primary-dark cursor-pointer"
                        : "text-primary-default hover:text-primary-dark cursor-pointer"
                      : "inactive text-inactive cursor-not-allowed"
                  }`}
                  data-tooltip-id="tooltip-tier-4"
                  data-tooltip-content="VIP Fund Form"
                  data-tooltip-place="right"
                  onClick={handleTab}
                >
                  <div className="w-6 h-6 lg:flex md:flex hidden justify-center">
                    {Object.keys(userLevel).length > 0 &&
                    parseInt(userLevel?.level) >= 5 &&
                    Object.keys(recordFive).length > 0 &&
                    recordFive?.approve ===
                      /* approved */ FORM_STATUS[2].label ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="w-5 h-5 self-center"
                      />
                    ) : (Object.keys(userLevel).length > 0 &&
                        parseInt(userLevel?.level) === 3 &&
                        userLevel?.status === "true") ||
                      (Object.keys(recordFour).length > 0 &&
                        recordFour?.approve !== "0") ? (
                      activeTab === "tab-tier-4" ? (
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="w-4 h-4 self-center"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="w-2 h-2 self-center"
                        />
                      )
                    ) : (
                      <FontAwesomeIcon
                        icon={faLock}
                        className="w-4 h-4 lg:flex md:flex hidden self-center text-inactive"
                      />
                    )}
                  </div>

                  <span className="lg:w-settings-tier-lg md:w-settings-tier-md w-fit lg:ml-4 md:ml-3 ml-0 flex whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    T<span className="lg:flex md:flex hidden">ier</span>
                    &nbsp;4
                  </span>

                  <div className="w-5 h-5 lg:flex md:flex hidden justify-center">
                    {recent === "4" && Object.keys(recordFour).length > 0 && (
                      <FontAwesomeIcon
                        icon={faFlag}
                        className="w-5 h-5 self-center text-button-success animate-bounce"
                      />
                    )}
                  </div>
                </button>
                <Tooltip
                  id="tooltip-tier-4"
                  className={`${
                    (userLevel !== undefined &&
                      userLevel !== null &&
                      userLevel?.level !== undefined &&
                      userLevel?.level !== null &&
                      Object.keys(userLevel?.level).length >= 5) ||
                    Object.keys(recordFour).length > 0
                      ? "bg-primary-default text-white"
                      : "hidden"
                  }`}
                />
              </li>

              <li
                className={`w-fit h-fit mb-8 ${page !== "settings" && "hidden"}`}
              >
                <button
                  id="tab-security"
                  className={`lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold cursor-pointer outline-none ${
                    activeTab === "tab-security"
                      ? "active text-primary-dark"
                      : "text-primary-default hover:text-primary-dark"
                  }`}
                  data-tooltip-id="tooltip-security"
                  data-tooltip-content="Security Settings"
                  data-tooltip-place="right"
                  onClick={handleTab}
                >
                  <div className="flex justify-center">
                    <FontAwesomeIcon
                      icon={faShield}
                      className="w-6 h-6 self-center"
                    />
                  </div>

                  <span className="lg:w-32 md:w-32 w-fit lg:ml-4 md:ml-3 ml-0 lg:flex md:flex hidden whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    Security
                  </span>
                </button>
                <Tooltip
                  id="tooltip-security"
                  className="bg-primary-default text-white"
                />
              </li>

              <li className="w-fit h-fit mb-0">
                <button
                  className="lg:w-full md:w-full w-fit px-2.5 flex flex-row items-center text-sm font-bold text-primary-default hover:text-primary-dark cursor-pointer outline-none"
                  onClick={onSignOut}
                >
                  <div className="flex justify-center">
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      className="w-6 h-6 self-center"
                    />
                  </div>

                  <span className="lg:w-32 md:w-32 w-fit lg:ml-4 md:ml-3 ml-0 lg:flex md:flex hidden whitespace-nowrap lg:justify-start md:justify-start justify-center">
                    Log out
                  </span>
                </button>
              </li>
            </ul>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="200"
            className="w-full lg:h-full md:h-full lg:mb-8 md:mb-6 mb-4 lg:flex md:flex hidden flex-col"
          >
            <StaticImage
              src="../../images/dashboard-images/img-dashboard-hero-04.png"
              alt="Bitcoin.bb Dashboard Image"
              loading="eager"
              quality={100}
              formats={["auto", "webp", "avif"]}
              className="w-full h-full flex flex-col drop-shadow-2xl rounded-3xl"
              placeholder="none"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
